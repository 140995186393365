import React from 'react';
import logo from './assets/logos.png'; // Replace with your logo file path
import './App.css'; // Replace with your styling or use inline styles
import Table from './components/Table';
import Anime from './components/anime';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Anime/> 
      </header>
      <Table/>
      {/* Other content of your website goes here */}
    </div>
  );
}

export default App;