import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/table.css';
function Table() {
     const ipoData = [{ipo: 'TCS', price: 150, gmp: '+10.5', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false},
     {ipo: 'INFY', price: 150, gmp: '+2.5', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false},
     {ipo: 'CTS', price: 150, gmp: '-2.5%', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false},
     {ipo: 'GEMINI', price: 150, gmp:  '2.5', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false},
     {ipo: 'GOOGLE', price: 150, gmp: '+2.5', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false},
     {ipo: 'APPLE', price: 150, gmp: '+2.5', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false},
     {ipo: 'ACCENTURE', price: 150, gmp: '-2.5', openingDate: '2023-01-01',closingDate: '2023-03-02',allotmentStatus: 'pending', listingDate: '2023-01-03', checkAllotment: false}]
    //  const filteredData = ipoData.filter((item) =>
    //  item.ipo.toLowerCase().includes(sea)
    //  )
     return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='mytable'>
                        <table>
                            <thead>
                                <tr>
                                    <th>S.NO</th>
                                    <th>IPO</th>
                                    <th>Price</th>
                                    <th><span>GMP</span></th>
                                    <th>Ex Listing</th>
                                    <th>Opening Date</th>
                                    <th>Closing Date</th>
                                    <th>Allotment Status</th>
                                    <th>Listing Date</th>
                                    <th>Check Allotment</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {ipoData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                     <td>{item.ipo}<div className='ExchangeInfo'>NSE</div></td>
                                     <td>{item.price}</td>
                                     <td><span className={item.gmp.startsWith('+') ? 'positive' : item.gmp.startsWith('-') ? 'negative':'subText'}>
                                     {item.gmp}</span></td>
                                     <td className={item.gmp.startsWith('+') ? 'green' : item.gmp.startsWith('-') ? 'red' : ''}>
                      {item.price + parseFloat(item.gmp)}
                    </td>
                                     <td>{item.openingDate}</td>
                                     <td>{item.closingDate}</td>
                                     <td>{item.allotmentStatus}</td>
                                     <td>{item.listingDate}</td>
                                     <td>
                                         <a href="https://rti.kfintech.com/ipostatus/" target="_blank">Click here</a>
                                     </td>
                                     
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Table;
